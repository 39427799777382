<template>
  <div :class="{ 'has-label': !!label }">
    <label v-if="!!label" :for="id">{{ label }}</label>
    <div class="datepicker-wrapper">
      <div v-if="!event.menuVisible" class="dates" :class="{ timezone: mode === 'datetime', disabled }">
        <span v-if="shouldShowDate" class="representation">My time: {{ dateRepresentation }}</span>
        <date-picker
          :model-value="localValue"
          :timezone="timezone"
          :mode="mode"
          :minute-increment="5"
          :masks="masks"
          :attributes="{ order: 1000 }"
          @update:model-value="onInput"
        >
          <template #default="{ inputValue, togglePopover}">
            <input
              :id="id"
              class="bg-white border px-2 py-1 rounded"
              :placeholder="placeholder"
              :value="inputValue"
              :disabled="disabled"
              @focus="togglePopover"
              @blur="complete"
              @keypress.enter="complete"
              @paste="paste"
            />
          </template>
        </date-picker>
        <multiselect
          v-if="mode === 'datetime'"
          :disabled="disabled"
          :can-clear="false"
          :value="timezone"
          :options="timezones"
          @change="onTimezoneChanged"
        />
      </div>
      <div v-else class="submit-event">
        <TextField v-model="event.subject" placeholder="Event subject" />
        <Button class="event-button" variant="icon" @click="resetEventSubmiting"><Icon size="md" name="close" title="Cancel"/></Button>
        <Button :disabled="!event.subject" class="event-button" variant="icon" title="Submit" @click="addToCalendar"
          ><Icon size="md" name="check"
        /></Button>
      </div>
      <Icon v-if="event.busy" size="md" name="loading" class="event-icon" title="Created" spin />
      <Icon v-else-if="event.created" size="md" name="calendar-check" class="event-icon created" title="Created" />
      <Button
        v-else-if="event.calendars.length && !event.menuVisible"
        :disabled="!localValue || event.busy"
        title="Add to my calendar"
        class="event-button"
        variant="icon"
        @click="toggleMenu"
        ><Icon name="calendar-plus" />
      </Button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import httpClient from '@/utils/httpClient';

import { parse, set, isValid } from 'date-fns';
import Cleave from 'cleave.js';
import './Datefield.style.css';
import { DatePicker } from 'v-calendar';
import Multiselect from '@/components/common/Multiselect';
import moment from 'moment-timezone';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import TextField from '@/components/common/TextField';

export default {
  components: {
    DatePicker,
    Multiselect,
    Button,
    Icon,
    TextField
  },
  props: {
    id: {
      type: String,
      default: () =>
        Math.random()
          .toString(36)
          .substr(2, 5)
    },
    value: {
      type: [String, Date],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'MM/DD/YYYY'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'date'
    },
    eventSubject: {
      type: String,
      default: ''
    }
  },
  emits: ['input', 'change', 'update:modelValue', 'update:value'],
  data() {
    let localValue;
    const myZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let timezone;
    const timezones = [
      { label: `My Time - ${myZone} (${moment.tz(myZone).zoneAbbr()})`, value: myZone },
      { label: 'USPTO - Eastern Time (ET)', value: 'America/New_York' },
      { label: 'EPO - Central European Time (CET)', value: 'Europe/Berlin' },
      { label: 'JPO - Japan Standard Time (JST)', value: 'Asia/Tokyo' },
      { label: 'KIPO - Korea Standard Time (KST)', value: 'Asia/Seoul' },
      { label: 'CNIPA - China Standard Time (CST)', value: 'Asia/Hong_Kong' }
    ];
    if (this.value) {
      localValue = new Date(this.value);
    }
    if (this.mode === 'datetime') {
      const zone = timezones.find(
        z =>
          moment(this.value)
            .tz(z.value)
            .format() === this.value
      );
      timezone = zone ? zone.value : myZone;
    }
    return {
      instance: null,
      localValue,
      timezone,
      myZone,
      timezones,
      masks: {
        input: 'MM/DD/YYYY'
      },
      event: {
        subject: this.eventSubject,
        busy: false,
        menuVisible: false,
        created: false,
        calendars: []
      }
    };
  },
  computed: {
    ...mapState({
      email: s => s.identity.email
    }),
    dateRepresentation() {
      if (this.mode !== 'datetime' || !this.value) {
        return '';
      }
      return moment(this.value).format('MM/DD/YYYY hh:mm a');
    },
    shouldShowDate() {
      return this.mode === 'datetime' && this.dateRepresentation && this.timezone !== this.myZone;
    }
  },
  watch: {
    value() {
      if (!this.value) {
        this.localValue = null;
        return;
      }
      this.localValue = new Date(this.value);
    }
  },
  async created() {
    try {
      this.event.busy = true;
      this.event.calendars = await httpClient.get(`/api/ms-graph-email-connector/calendars/${this.email}`);
    } catch (error) {
    } finally {
      this.event.busy = false;
    }
  },
  mounted() {
    const delimiters = this.mode === 'datetime' ? ['/', '/', ' ', ':', ' '] : ['/', '/'];
    const blocks = this.mode === 'datetime' ? [2, 2, 4, 2, 2, 2] : [2, 2, 4];

    this.instance = new Cleave(this.$el.querySelector(`input`), {
      delimiters,
      blocks,
      uppercase: true
    });
    this.instance.setRawValue(this.value);
  },
  unmounted() {
    this.instance && this.instance.destroy();
  },

  methods: {
    toggleMenu() {
      this.event.menuVisible = !this.event.menuVisible;
    },
    async addToCalendar() {
      this.event.menuVisible = false;
      this.event.busy = true;

      try {
        const timezone = this.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
        await httpClient.post(`/api/ms-graph-email-connector/calendars/${this.email}/create/event`, {
          subject: this.event.subject,
          isAllDay: this.mode !== 'datetime',
          start: {
            dateTime: moment(this.localValue).toISOString(),
            timeZone: timezone
          },
          end: {
            dateTime: this.localValue.toISOString(),
            timeZone: moment(this.localValue)
              .add(1, 'hours')
              .toISOString()
          }
        });

        this.event.created = true;
      } catch (error) {
        this.$toast.error({
          title: 'Failed to create event in calendar',
          message: `Please, try again later or contact our development team.`
        });
      } finally {
        this.resetEventSubmiting();
      }
    },
    resetEventSubmiting() {
      this.event.menuVisible = false;
      this.event.busy = false;
      this.event.subject = this.eventSubject;
    },
    emitChanges(v) {
      if (!v) {
        return;
      }
      let value;
      if (v instanceof Date) {
        value = v;
      } else {
        value = moment(v).toDate();
      }

      if (!value || value.toString() === 'Invalid Date') {
        return;
      }

      this.event.created = false;

      value.setSeconds(0);
      if (this.mode !== 'datetime') {
        value = this.setMidday(value);
      } else {
        value = moment(value)
          .tz(this.timezone)
          .format();
      }
      this.$emit('change', value);
      this.$emit('input', value);
      this.$emit('update:modelValue', value);
      this.$emit('update:value', value);
    },
    onInput(date) {
      if (isValid(date)) {
        this.emitChanges(date);
      }
    },
    complete(e) {
      e.stopPropagation();
      e.preventDefault();
      let value = this.instance.getRawValue();

      const regexp = this.mode === 'datetime' ? /(\d{12})(AM|PM)/ : /\d{8}/;

      if (regexp.test(value) || value.length === 0) {
        this.emitChanges(value.toUpperCase());
      } else {
        this.localValue = null;
      }
    },
    paste(event) {
      event.preventDefault();

      const match = event.clipboardData.getData('text').match(new RegExp('^\\d\\d?/\\d\\d?/\\d\\d\\d?\\d?'));

      if (!match) {
        return;
      }
      const date = parse(match[0], 'MM/dd/yyyy', new Date());
      if (isValid(date)) {
        this.emitChanges(date);
      }
    },
    setMidday(date) {
      return set(date, { hours: 12, minutes: 0, seconds: 0 });
    },
    onTimezoneChanged(v) {
      this.timezone = v;
      this.emitChanges(this.localValue);
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  display: grid;
  grid-template-rows: max-content;

  &.has-label {
    grid-template-rows: max-content max-content;
  }

  > * {
    min-width: 0;
    min-height: 0;
  }

  label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
    margin-bottom: 5px;
  }

  .datepicker-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    position: relative;
    height: 35px;
    .dates {
      display: grid;
      grid-template-columns: 1fr auto;
      .representation {
        position: absolute;
        right: 0;
        top: -20px;
        font-style: italic;
        font-size: 0.75rem;
      }

      &.timezone {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  input {
    padding: 8px 12px;
    font-size: 0.85rem;
    font-weight: 400;
    border-radius: 2px;
    border: 1px solid var(--theme-on-background-accent);
    color: var(--theme-on-background);
    background: var(--theme-background);
    outline: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:not([disabled]) {
      // &:hover {
      //   border-color: var(--theme-on-background);
      //   color: var(--theme-on-background);
      // }

      &:focus,
      &:active {
        border-color: var(--theme-on-background);
        color: var(--theme-on-background);
        outline: none;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  .submit-event {
    display: grid;
    grid-template-columns: 1fr auto auto;
  }

  .event-button {
    padding: 0;
    margin-left: 0.5rem;
  }

  .event-icon {
    padding-top: 15px;
    margin-left: 0.5rem;
  }

  .created {
    color: var(--theme-success);
  }
}
</style>
